.sidenav {
  width: 294px;
  background-color: #1c3155;
  display: flex;
  flex-direction: column;
}

.sidenavClosed {
  composes: sidenav;
  transition: width 0.3s ease-in-out;
  width: 12rem;
}

.sidenav > * {
  padding-left: 10px;
  padding-right: 10px;
}

.sidenav nav {
  flex: 1;
  overflow: auto;
}

.sidenav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidenav li {
  margin: 25px 0;
}

.sidenav nav a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  white-space: pre;
  padding: 0.5rem;
  border-radius: 18.5px;
  color: white;
  text-decoration: none;
  gap: 1rem;
}

.sidenav nav a:hover {
  background: #608fff;
}

.sidenav nav a.active {
  background: hsl(224, 98%, 58%);
  color: white;
}

.sidenav nav a.pending {
  color: hsl(224, 98%, 58%);
}
